/* navbar styling */
.navbar_bg{
  background-color: #3A0063;
}


.text_color{
  color: #feca0a;
}
.textCol{
  color: rgb(1, 161, 28);
  font-weight: 700;
}
a:hover {
  color: white;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: #3A0063;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.toggler-icon{
  background: #feca0a;
  display: block;
  margin: 4px;
  height: 4px;
  width: 30px;
}

/* Contact form styling*/
.btn_bg{
  background: #3A0063;
  color: #feca0a;
  padding: 10px;
  border-radius: 10px;
  font-weight: bold;
}

.form_text{
   color: #3A0063;
   font-weight: 500;
}


/* Home page */

.carousel-img{
  height: 500px;
}

.jumbo_bg{
  background-color: #feca0a;
  color: #3A0063;
  text-align: center;
  padding: 20px;
  font-weight: bold;
}

.arrow_col{
  color: #3A0063;
}

.Success_story{
  background-color: #feca0a;
}

.title{
  padding: 10px;
  color: #feca0a;
  margin-bottom: 10px;
}

/* our services short in Home Page */

.card-image{
  height: 256px;
}

.card-title{
  color: #3A0063;
}

.card-text{
  font-size: 18px;
}

.button_container{
  display: flex;
  justify-content: center;
}

/* Feedback in Home */
.video_card{
  border: 3px solid #3A0063;
  border-radius: 25px;
  background-color: #feca0a;
}

.video{
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.video_date{
  display: flex-end;
}
/* services */

.service_img{
  border: 3px solid #3A0063 ;
  border-radius: 20px;
}

/* About us */

.lead{
  color: #3A0063;
}
.team{
  color: #feca0a;
  background-color: #3A0063;
  border: 2px solid #feca0a;
  padding: 20px;
  font-weight: bold;
}
#about{
  background-color: rgb(224, 223, 221);
  color: #3A0063;
}

#about img{
  border-radius: 50%;
  border: 2px solid #feca0a;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
p{
  font-size: 22px;
}
#advisors{
  background-color: #feca0a;
  color: #3A0063;
  padding: 0;
}


.teams{
  color: #feca0a;
  background-color: #3A0063;
  border: 2px solid #feca0a;
  padding: 20px;
  font-weight: bold;
  border-radius: 100px;
  margin-bottom: 10px;
}
.personal{
  border-bottom: 2px solid #3A0063;
}

/* Footer styles */

.footer_main{
  background-color: #3A0063;
}

/* Our Services */
/* Services 1 */
.texting{
  color: #3A0063;
  font-size: 15px;
}

.sub_service_head{
  color: #3A0063;
}

.listing{
  color: #3A0063;
  text-decoration: none;
}

/* About us image styling */
.card-img-top{
  width: 440px;
  height: 300px;
  border-bottom: 4px solid #3A0063;
}

.card-about{
  border: 4px solid #3A0063;
  border-radius: 10px;
  margin: 10px;
  border-left: 4px solid #3A0063;
  height: 623px;
}

#advisors img{
  height: 250px;
}

.font-about{
  font-size: 18px;
  text-align: justify;
}

/* Contact form styling*/
.btn_bg{
  background: #3A0063;
  color: #feca0a;
  padding: 10px;
  border-radius: 10px;
  font-weight: bold;
}

.form_text{
   color: #3A0063;
   font-weight: bold;
}